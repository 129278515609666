import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/staff',
    name: 'Staff',
    component: () => import('../views/Staff.vue'),

  },
  {
    path: '/glensaugh',
    name: 'Glensaugh',
    component: () => import('../views/Glensaugh.vue'),

  },
  {
    path: '/balruddery',
    name: 'Balruddery',
    component: () => import('../views/Balruddery.vue'),

  },
  {
    path: '/liveData',
    name: 'Live Data',
    component: () => import('../views/LiveData.vue'),

  },
]

const router = new VueRouter({
  routes
})

export default router
